.webtim-totop__button {
    position: fixed;
    bottom: 0;
    width: 48px;
    height: 40px;
    background-color: rgba(51, 51, 51, .9);
    border: 1px solid rgba(51, 51, 51, .9);
    border-radius: 3px 3px 0 0;
    z-index: 9999;
    transition: all .4s ease;
}

.webtim-totop__button.right {
    right: 105px;
    left: unset;
}
.webtim-totop__button.center {
    right: 50%;
    transform: translateX(50%);
}
.webtim-totop__button.left {
    right: unset;
    left: 105px;
}

.webtim-totop__button::after {
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    margin-left: auto;
    margin-right: auto;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
}

.webtim-totop__button:hover {
    opacity: .8;
}

.webtim-totop__button.disabled {
    opacity: 0;
    visibility: hidden;
}